// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import queryString from 'query-string';

import {
    DataProvider,
} from 'react-admin';

import { ResourceURL } from '@/utils';
import { fetchJsonWithAuthToken } from '@/authProvider';

export default <DataProvider> {
    delete: async (resource, params) => {
        const query: {
            resource: string;
            resource_id: string;
            file: string;
        } = {
            resource: params.meta.resource,
            resource_id: params.meta.resource_id,
            file: params.id as string,
        };

        const url = ResourceURL(`/${resource}?${queryString.stringify(query)}`);
        const { json } = await fetchJsonWithAuthToken(url, {
            method: 'DELETE',
        });
        return {
            data: json,
        };
    },
    deleteMany: (resource, params) => Promise.all(params.ids.map(async (id) => {
        const query: {
            resource: string;
            resource_id: string;
            file: string;
        } = {
            resource: params.meta.resource,
            resource_id: params.meta.resource_id,
            file: id as string,
        };

        const url = ResourceURL(`/${resource}?${queryString.stringify(query)}`);
        return fetchJsonWithAuthToken(url, {
            method: 'DELETE',
        });
    })).then(() => ({ data: [] })),
};
