// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import { useRecordContext } from 'react-admin';

import { FileEntity } from '@/types';
import { truncateText } from '@/utils';

type FileFieldProps = object;

const FileField: FC<FileFieldProps> = () => {
    const record = useRecordContext<FileEntity>();

    if (!record) {
        return null;
    }

    return (
        <span>{truncateText(record.id, 50, 'characters')}</span>
    );
};

export default FileField;
