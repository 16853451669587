// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { useMediaQuery } from '@mui/material';
import {
    useRecordContext, useTranslate,
    useThemesContext, useTheme,
} from 'react-admin';
import get from 'lodash/get';
import JsonView from '@uiw/react-json-view';
import { lightTheme } from '@uiw/react-json-view/light';
import { basicTheme } from '@uiw/react-json-view/basic';

import { CopyButton } from '@/common';

interface JsonFieldItemProps {
    source: string;
    label: string;
};

const JsonFieldItem: FC<JsonFieldItemProps> = ({
    source,
    label,
}) => {
    const record = useRecordContext();
    if (!record) {
        return null;
    }

    const value = get(record, source, undefined);
    if (!value) {
        return null;
    }

    const { darkTheme, defaultTheme } = useThemesContext();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
        noSsr: true,
    });
    const [theme] = useTheme(
        defaultTheme || (prefersDarkMode && darkTheme ? 'dark' : 'light')
    );

    return (
        <ListItem
            divider
        >
            <Grid
                container
                sx={{ width: '90%' }}
            >
                <Grid size={{ xs: 3 }}>
                    {label}
                </Grid>
                <Grid size={{ xs: 7 }}>
                    {
                        typeof value !== 'object' ? (
                            value
                        ) : (
                            <JsonView
                                value={value}
                                style={theme === 'dark' ? basicTheme : lightTheme}
                                displayDataTypes={false}
                                collapsed
                            />
                        )
                    }
                </Grid>
            </Grid>
        </ListItem>
    );
};

interface JsonFieldProps {
    resource: string;
    source: string;
    label?: string;
};

const JsonField: FC<JsonFieldProps> = ({
    resource,
    source,
    label,
}) => {
    const record = useRecordContext();
    if (!record) {
        return null;
    }

    const value = get(record, source, undefined);
    if (!value || typeof value !== 'object') {
        return null;
    }

    const translate = useTranslate();

    return (
        <Card
            sx={{ mb: 2 }}
        >
            <CardHeader
                title={label || translate(`resources.${resource}.fields.${source}`, {
                    _: source,
                })}
                action={(
                    <CopyButton
                        value={JSON.stringify(value, null, 2)}
                    />
                )}
                disableTypography
            />
            <Divider />
            <CardContent>
                <List>
                    {Object.keys(value).map((key) => (
                        <JsonFieldItem
                            key={key}
                            source={`${source}.${key}`}
                            label={key}
                        />
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default JsonField;
