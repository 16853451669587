// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ResourceProps } from 'react-admin';

import fileDataProvider from './fileDataProvider';
import FileList from './FileList';

const FileResourceProps: ResourceProps = {
    name: 'files',
};

export {
    FileResourceProps,
    FileList,
    fileDataProvider,
};
