// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';

import { JsonField } from '@/common';

type CollectionDetailsProps = object;

const CollectionDetails: FC<CollectionDetailsProps> = () => (
    <>
        <JsonField
            resource='collections'
            source='chunk_config'
        />
        <JsonField
            resource='collections'
            source='info'
        />
    </>
);

export default CollectionDetails;
