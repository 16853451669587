// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import {
    TextInput, required, useDataProvider,
    useTranslate, useRecordContext,
} from 'react-admin';
import { useFormState } from 'react-hook-form';

import {
    CollectionData, Collection,
} from '@/types';
import { GlobalContext, CollectionContext } from '@/context';
import { ConfigurableInputs } from '@/common';
import { ModelSelectInput } from '@/provider/model';

export const transformCollectionSetupData = (
    formValues: any,
    mode: 'create' | 'edit',
    dirtyFields: any,
): CollectionData => {
    const {
        name, embedding_model: embeddingModel, chunk_config: chunkConfig
    } = formValues;
    if (mode === 'create') {
        return {
            name,
            embedding_model: embeddingModel,
            chunk_config: chunkConfig,
        };
    }
    // edit mode
    const data: CollectionData = {};
    if (Object.keys(dirtyFields).includes('embedding_model')) {
        data.embedding_model = embeddingModel;
    }
    if (Object.keys(dirtyFields).includes('chunk_config')) {
        data.chunk_config = chunkConfig;
    }
    return data;
};

type CollectionSetupProps = object;

const CollectionSetup: FC<CollectionSetupProps> = () => {
    const { collectionConfiguration } = useContext(GlobalContext);
    if (!collectionConfiguration) {
        return null;
    }

    const record = useRecordContext<Collection>();
    const { mode } = useContext(CollectionContext);
    const { dirtyFields } = useFormState();
    const dataProvider = useDataProvider();
    const translate = useTranslate()

    const validateName = (value: string): Promise<string | undefined> | undefined => (
        (mode === 'create' || Object.keys(dirtyFields).includes('name')) ? (
            dataProvider.getList(
                'collections',
                { filter: { name: value } }
            ).then((response) => {
                const { total } = response;
                if (total && total > 0) {
                    return translate('message.validate.unique', { name: 'Collection name'});
                }
                return undefined;
            })
        ) : undefined
    );

    return (
        <>
            <TextInput
                source='name'
                variant='outlined'
                validate={[required(), validateName]}
                disabled={!!(record)}
            />
            <ModelSelectInput
                source='embedding_model'
                variant='outlined'
                validate={required()}
                filter={{ model_type: 'text-embedding', valid_only: true }}
            />
            <ConfigurableInputs
                parameterRules={collectionConfiguration.parameter_rules}
            />
        </>
    );
};

export default CollectionSetup;
