// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import { ArrayField, useListContext, useTranslate } from 'react-admin';

import { JsonField } from '@/common';

const CollectionSamplesView: FC = () => {
    const { data } = useListContext();
    if (!data) {
        return null;
    }

    const translate = useTranslate();

    return data.map((record: any, index: number) => (
        <JsonField
            resource='collections'
            source={`samples[${index}]`}
            label={translate('label.point', { id: record.id})}
        />
    ));
};

type CollectionSamplesProps = object;

const CollectionSamples: FC<CollectionSamplesProps> = () => (
    <ArrayField
        resource='collections'
        source='samples'
    >
        <CollectionSamplesView />
    </ArrayField>
);

export default CollectionSamples;
