// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { ReactNode } from 'react';
import { RaRecord } from 'react-admin';

export enum ParameterType {
    FLOAT = 'float',
    INT = 'int',
    BOOLEAN = 'boolean',
    STRING = 'string',
    SECRET = 'secret',
    TEXT = 'text',
    OBJECT = 'object',
};

export interface ParameterRule {
    name: string;
    label: {
        [key: string]: string;
    };
    placeholder: {
        [key: string]: string;
    };
    type: ParameterType;
    help: {
        [key: string]: string;
    };
    required: boolean;
    advanced: boolean;
    default: number | boolean | string;
    disabled: boolean;
};

export interface NumberParameterRule extends ParameterRule {
    min?: number;
    max?: number;
    precision?: number;
};

export type BooleanParameterRule = ParameterRule;

export interface StringParameterRule extends ParameterRule {
    options: ParameterRule[];
};

export type TextParameterRule = ParameterRule;

export interface ObjectParameterRule extends ParameterRule {
    parameters: ParameterRule[];
};

export interface ConfigurableInputBundle {
    name: string;
    type: ParameterType;
    advanced: boolean;
    components: ConfigurableInputBundle[] | ReactNode;
}

export interface MetaParams {
    queryParams?: {
        [key: string]: string;
    }
};

export interface Group extends RaRecord {
    name: string;
    description: string;
    color: string;
};

export interface FileEntity {
    id: string;
    path: string;
}
