// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import { required } from 'react-admin';

import { GlobalContext } from '@/context';
import { ConfigurableInputs } from '@/common';
import { CollectionSelectInput } from '@/collection/form';

type AssistantCollectionFormProps = object;

const AssistantCollectionForm: FC<AssistantCollectionFormProps> = () => {
    const { collectionConfiguration } = useContext(GlobalContext);
    if (!collectionConfiguration) {
        return null;
    }

    return (
        <>
            <CollectionSelectInput
                source='collection_config.collection_id'
                validate={required()}
            />
            <ConfigurableInputs
                parameterRules={collectionConfiguration.retriever_config.parameter_rules}
            />
        </>
    );
};

export default AssistantCollectionForm;
