// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    ReferenceInput,
    ReferenceInputProps,
    AutocompleteInput,
} from 'react-admin';

type CollectionSelectInputProps = Omit<ReferenceInputProps, 'reference'>;

const CollectionSelectInput: FC<CollectionSelectInputProps> = ({
    source,
    validate,
    label,
    ...rest
}) => (
    <ReferenceInput
        source={source}
        reference='collections'
        {...rest}
    >
        <AutocompleteInput
            label={label}
            variant='outlined'
            validate={validate}
            debounce={500}
            optionText='name'
            margin='none'
        />
    </ReferenceInput>
);

export default CollectionSelectInput;
