// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { matchPath, useLocation } from 'react-router';
import {
    useTranslate, CreateBase, EditBase, Form,
} from 'react-admin';

import { CollectionState } from '@/state';
import { CollectionContext } from '@/context';
import { CollectionFormActions, CollectionSetup, CollectionUpload } from '@/collection/form';
import { VectorStoreSetup } from '@/collection/vectorStore';

const steps = ['label.collection.interface.step1', 'label.collection.interface.step2', 'label.collection.interface.step3'];

const StepperForm: FC = () => {
    const {
        activeStep, setMode,
    } = useContext(CollectionContext);
    const location = useLocation();
    const translate = useTranslate();

    const match = matchPath('/collections/create', location.pathname);
    let Root: any = EditBase;
    if (match) {
        Root = CreateBase;
        setMode('create');
    } else {
        setMode('edit');
    }

    const renderStep = (step: number): JSX.Element | null => {
        switch (step) {
            case 0:
                return (
                    <CollectionSetup />
                );
            case 1:
                return (
                    <VectorStoreSetup />
                );
            case 2:
                return (
                    <CollectionUpload />
                );
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                p: 2,
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={translate(label)} {...stepProps}>
                            <StepLabel>{translate(label)}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Root
                mutationMode='pessimistic'
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid
                            size={{
                                xs: 12, sm: 12, md: 6, lg: 6, xl: 6
                            }}
                            offset={{
                                xs: 0, sm: 0, md: 3, lg: 3, xl: 3
                            }}
                        >
                            <Paper
                                sx={{
                                    height: '65vh',
                                    overflow: 'auto',
                                    p: 2,
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                {renderStep(activeStep)}
                            </Paper>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12, sm: 12, md: 8, lg: 8, xl: 8
                            }}
                            offset={{
                                xs: 0, sm: 0, md: 2, lg: 2, xl: 2
                            }}
                        >
                            <Paper
                                sx={{
                                    p: 1,
                                }}
                            >
                                <CollectionFormActions />
                            </Paper>
                        </Grid>
                    </Grid>
                </Form>
            </Root>
        </Box>
    );
};

const CollectionInterface: FC = () => (
    <CollectionState>
        <StepperForm />
    </CollectionState>
);

export default CollectionInterface;
