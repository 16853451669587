// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import { RecordContextProvider, useTranslate } from 'react-admin';

import { ChatBotMessage } from '@/types';
import { BasicPopover, JsonField } from '@/common';
import { truncateText } from '@/utils';

const Root = styled(Box, {
    name: 'GFMessageReferences',
    slot: 'Root',
})(() => ({
    margin: '10px',
}));

interface MessageReferencesProps {
    message: ChatBotMessage;
};

const MessageReferences: FC<MessageReferencesProps> = ({ message }) => {
    if (!message || !message.references || message.references.length === 0) {
        return null;
    }

    const translate = useTranslate();

    return (
        <Root>
            <Typography
                variant='caption'
                color='textSecondary'
            >
                {translate('label.references')}
            </Typography>
            <ol
                style={{
                    listStyleType: 'decimal',
                    padding: 0,
                }}
            >
                {
                    message.references.map((reference, index) => {
                        let label = reference.id;
                        if (reference.metadata && Object.keys(reference.metadata).includes('file_name')) {
                            label = reference.metadata.file_name;
                        }

                        return (
                            <li key={reference.id}>
                                <BasicPopover
                                    component='button'
                                    componentProps={{
                                        title: label,
                                        label: truncateText(label, 35, 'characters'),
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box
                                        minWidth='320px'
                                        maxWidth='700px'
                                        maxHeight='400px'
                                        padding={2}
                                    >
                                        <RecordContextProvider value={message}>
                                            <JsonField
                                                resource='messages'
                                                source={`references[${index}]`}
                                                label={translate('label.reference', { id: reference.id})}
                                            />
                                        </RecordContextProvider>
                                    </Box>
                                </BasicPopover>
                            </li>
                        );
                    })
                }
            </ol>
        </Root>
    );
};

export default MessageReferences;
