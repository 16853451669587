// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { RaRecord, Identifier } from 'react-admin';

import { Group, FileEntity } from './common';
import { ProviderModelConfigWithEntity } from './model';

export type AssistantGroup = Group;

export enum ContextSource {
    FILES = 'files',
    COLLECTIONS = 'collections',
}

export enum AssistantStatus {
    DRAFTED = 'drafted',
    PUBLISHED = 'published',
}

export interface Assistant extends RaRecord {
    name: string;
    description: string;
    opening_statement: string;
    suggested_questions: {
        question: string;
    }[];
    status: AssistantStatus;
    avatar: string;
    related_model: ProviderModelConfigWithEntity;
    group_id: Identifier;
    is_pinned: boolean;
    files: FileEntity[];
}
