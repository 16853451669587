// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    ListBase, Datagrid,
    BulkDeleteWithConfirmButton,
} from 'react-admin';

import FileField from './FileField';

interface FileListProps {
    resource: string;
    resourceId: string;
};

const FileBulkActionButtons: FC<FileListProps> = ({
    resource,
    resourceId,
}) => (
    <BulkDeleteWithConfirmButton
        mutationMode='pessimistic'
        mutationOptions={{
            meta: { resource, resource_id: resourceId },
        }}
    />
);

const FileList: FC<FileListProps> = ({
    resource,
    resourceId,
}) => (
    <ListBase
        resource='files'
        filter={{ resource, resource_id: resourceId }}
    >
        <Datagrid
            bulkActionButtons={(
                <FileBulkActionButtons resource={resource} resourceId={resourceId} />
            )}
            rowClick={false}
        >
            <FileField />
        </Datagrid>
    </ListBase>
);

export default FileList;
