// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, {
    useState, FC, ReactNode, useMemo,
} from 'react';
// import cloneDeep from 'lodash/cloneDeep';

import {
    VectorStoreEntity,
} from '@/types';
import { CollectionContext } from '@/context';

interface CollectionStateProps {
    children: ReactNode,
};

export const CollectionState: FC<CollectionStateProps> = ({ children }) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [remainingFiles, setRemainingFiles] = useState<number>(0);
    const [selectedVectorStore, setSelectedVectorStore] = useState<VectorStoreEntity | undefined>(undefined);
    const setVectorStore = (
        vectorStore: VectorStoreEntity | undefined
    ): void => {
        if (!vectorStore) {
            setSelectedVectorStore(undefined);
            return;
        }
        setSelectedVectorStore(vectorStore);
        // ToDo: Fix this
        // setSelectedVectorStore({
        //     ...deepCopy,
        //     parameter_rules: prefixRuleName(
        //         'vector_store_config',
        //         (deepCopy as VectorStoreEntity).parameter_rules
        //     )
        // });
    };

    const contextValue = useMemo(() => ({
        activeStep,
        setActiveStep,
        mode,
        setMode,
        selectedVectorStore,
        setVectorStore,
        remainingFiles,
        setRemainingFiles,
    }), [activeStep, mode, selectedVectorStore, remainingFiles]);

    return (
        <CollectionContext.Provider
            value={contextValue}
        >
            {children}
        </CollectionContext.Provider>
    );
};
