// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';
import {
    List, Datagrid, TextField,
    ReferenceField, NumberField,
} from 'react-admin';

import { ModelField } from '@/provider/model';
import { VectorStoreField } from '@/collection/vectorStore';
import { CollectionShowTabs } from '@/collection/show';

type CollectionListProps = object

const CollectionList: FC<CollectionListProps> = () => (
    <List>
        <Datagrid
            rowClick='edit'
            expand={<CollectionShowTabs />}
        >
            <TextField source='name' />
            <ReferenceField source='embedding_model.model_name' reference='models'>
                <ModelField />
            </ReferenceField>
            <ReferenceField source='vector_store' reference='vector-stores'>
                <VectorStoreField />
            </ReferenceField>
            <NumberField source='info.points_count' />
        </Datagrid>
    </List>
);

export default CollectionList;
