// Copyright (C) 2024 Reveal AI
//
// SPDX-License-Identifier: MIT

import { createContext } from 'react';

import {
    SessionType, Team, Membership, Identity,
    AboutSystem, CollectionUIConfiguration,
} from '@/types';

interface GlobalContextInterface {
    currentUser: Identity | undefined;
    sessionTypes: SessionType[] | [],
    aboutSystem: AboutSystem | undefined;
    currentTeam: Team | undefined;
    currentMembership: Membership | undefined;
    switchTeam: (user: Identity, team: Team) => void;
    collectionConfiguration: CollectionUIConfiguration | undefined;
};

export const GlobalContext = createContext<GlobalContextInterface>({
    currentUser: undefined,
    sessionTypes: [],
    aboutSystem: undefined,
    currentTeam: undefined,
    currentMembership: undefined,
    switchTeam: () => {},
    collectionConfiguration: undefined,
});
