// Copyright (C) 2025 Reveal AI
//
// SPDX-License-Identifier: MIT

import React, { FC } from 'react';

import { TabsController, TabItems } from '@/common';
import CollectionDetails from './CollectionDetails';
import CollectionSamples from './CollectionSamples';

type CollectionShowTabsProps = object;

const CollectionShowTabs: FC<CollectionShowTabsProps> = () => {
    const tabItems: TabItems = {};
    tabItems['label.details'] = (
        <CollectionDetails />
    );
    tabItems['label.samples'] = (
        <CollectionSamples />
    );

    return (
        <TabsController
            tabLabels={Object.keys(tabItems)}
        >
            {
                Object.values(tabItems)
            }
        </TabsController>
    );
};

export default CollectionShowTabs;
